
import TriggerComponent from "@/components/Flowbuilder/Builder/Components/TriggerComponent";
import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import LoopComponent from "@/components/Flowbuilder/Builder/Components/LoopComponent";
import ChannelComponent from "@/components/Flowbuilder/Builder/Components/ChannelComponent";
import DecisionComponent from "@/components/Flowbuilder/Builder/Components/DecisionComponent";
import FunnelComponent from "@/components/Flowbuilder/Builder/Components/FunnelComponent";

export default class WorkflowExport {

    workflow: any;

    collection: ViewComponent[];

    constructor(workflow: object, collection: ViewComponent[]) {
        this.workflow = workflow;
        this.collection = collection;
    }

    export(){

        const trigger : TriggerComponent | null = this.collection[0].getComponent() as TriggerComponent;

        return {
            id: this.workflow.id,
            userId: this.workflow.userId,
            name: this.workflow.name,
            published: false,
            triggerType: trigger?.getType(),
            triggerParameters: trigger?.getParameters(),
            steps: this.createSteps(
                this.collection
            )
        };
    }


    createSteps(collection: any[]): any[] {

        const steps : any[] = [];

        collection.forEach((item: ViewComponent) => {

            if (item.getType() === ComponentType.LOOP) {

                const loop : LoopComponent = item.getComponent() as LoopComponent;

                steps.push({
                    componentType: ComponentType.LOOP,
                    field: loop.getField(),
                    componentReferenceId: loop.getComponentReferenceId(),
                    childNodes: this.createSteps(
                        item.getChildNodes()
                    ),
                    x: item.getHtmlContainer().offsetLeft,
                    y: item.getHtmlContainer().offsetTop,
                })
            }

            if (item.getType() === ComponentType.FUNNEL) {

                const funnel : FunnelComponent = item.getComponent() as FunnelComponent;

                const step: any = {
                    componentType: ComponentType.FUNNEL,
                    field: funnel.getField(),
                    comparison: funnel.getComparison(),
                    value: funnel.getValue(),
                    x: item.getHtmlContainer().offsetLeft,
                    y: item.getHtmlContainer().offsetTop,
                };

                steps.push(step)
            }

            if (item.getType() === ComponentType.CHANNEL) {

                const channel : ChannelComponent = item.getComponent() as ChannelComponent;

                const mapping: any = {};

                for (const i in channel.getRequestBody()) {
                    mapping[i] = channel.getRequestBody()[i];
                }

                const step: any = {
                    componentType: ComponentType.CHANNEL,
                    appAccount: channel.getAppAccount(),
                    mapping: channel.getRequestBody(),
                    parameters: channel.getParameters(),
                    action: channel.getOperationId(),
                    componentReferenceId: channel.getComponentReferenceId(),
                    x: item.getHtmlContainer()?.offsetLeft,
                    y: item.getHtmlContainer()?.offsetTop,
                };

                if (channel.getId() !== null && channel.getId() !== '') {
                    step.id =  channel.getId();
                }

                steps.push(step)
            }

            if (item.getType() === ComponentType.DECISION_TRUE) {

                const step: any = {
                    componentType: ComponentType.DECISION_TRUE,
                    childNodes: this.createSteps(item.getChildNodes()),
                    x: item.getHtmlContainer().offsetLeft,
                    y: item.getHtmlContainer().offsetTop,
                };

                steps.push(step)
            }

            if (item.getType() === ComponentType.DECISION_FALSE) {

                const step: any = {
                    componentType: ComponentType.DECISION_FALSE,
                    childNodes: this.createSteps(item.getChildNodes()),
                    x: item.getHtmlContainer().offsetLeft,
                    y: item.getHtmlContainer().offsetTop,
                };

                steps.push(step)
            }

            if (item.getType() === ComponentType.DECISION) {

                const decision : DecisionComponent = item.getComponent() as DecisionComponent;

                const step: any = {
                    componentType: ComponentType.DECISION,
                    childNodes: this.createSteps(item.getChildNodes()),
                    x: item.getHtmlContainer().offsetLeft,
                    y: item.getHtmlContainer().offsetTop,
                    rules: decision.getRules()
                };

                if (decision.getId() !== null && decision.getId() !== '') {
                    step.id = decision.getId();
                }



                steps.push(step)
            }
        })

        return steps;
    }
}