

import {Options, Vue} from "vue-class-component";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {ref} from "vue";
import DateFormat from "@/components/Flowbuilder/Helper/DateFormat";



@Options({
    props: {
        component: Object
    },
    components: { VueDatePicker },
    emits: ['updateSchedule'],
    data() {
        return {
            date: ref(),
            time: ref(),
            langString: 'nl-NL',
            schedule: {
                dateTime: null,
                type: 'once'
            }
        }
    },
    beforeMount() {
        const parts = this.$props.component.parameters.dateTime.split(' ');
        this.date = parts[0];
        this.time = parts[1];
    },
    methods: {
        handleDate(date: Date) {

            const dateOnce = (new DateFormat()).dateToString(date);
            const parts = this.schedule.dateTime.split(' ');

            this.dateTime = dateOnce + ' ' + parts[1];
            this.$emit('updateSchedule', this.schedule)
        },
        handleTime(time: any): void {

            if (this.schedule.dateTime === null) {
                return;
            }

            const parts = this.schedule.dateTime.split(' ');
            this.schedule.dateTime = parts[0] + ' ' + (new DateFormat()).timeToString(time);

            this.$emit('updateSchedule', this.schedule)
        }
    },
    mounted() {
        this.schedule.dateTime = (new DateFormat()).dateToString(new Date()) + ' 00:00:00';
    }
})

export default class ScheduleOnce extends Vue {}
