

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import ChannelAppAccount from "@/components/Flowbuilder/Channel/AppAccount/ChannelAppAccount.vue";
import CreateAccount from "@/components/Flowbuilder/Channel/AppAccount/CreateAccount.vue";
import {toast} from "vue3-toastify";
import Modal from "@/components/Modal/Modal.vue";

@Options({
    components: {Modal, CreateAccount, ChannelAppAccount},
    data() {
        return {
            app: null,
            appAccounts: [],
            apps: [],
            createAccount: false,
            modalOpen: false
        }
    },
    created() {
        this.app = this.$store.getters.getApp
    },
    methods: {
        test() {
            toast.success('Account removed!');
        },
        appAccountCreated() {

            const parts = this.$route.path.split('/');

            ApiRequest('GET', '/app/account?appName=' + parts[2]).then(response => {
                this.appAccounts = response;
            });

            this.closeModal();

        },
        cancelCreateAppAccount() {
            console.log('canceld')
        },
        setCreateAccount(create: boolean): void {
            this.createAccount = create;
        },
        deleteAppAccount(appAccountId: string): void
        {
            ApiRequest('POST', '/app/account/' + appAccountId + '/remove').then(() => {
                this.getAppAccounts()
                toast.success('Account removed!');
            });
        },
        getAppAccounts() {

            const parts = this.$route.path.split('/');

            ApiRequest('GET', '/app/account?appName=' + parts[2]).then(response => {
                this.appAccounts = response;
            });
        },
        openModal() {
            this.modalOpen = true;
        },
        closeModal() {
            this.modalOpen = false;
        },
        capitalize(str: string): string
        {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
    },
    mounted() {
        const parts = this.$route.path.split('/');

        ApiRequest('GET', '/app?filter=' + parts[2]).then(response => {
            if (response.length > 0) {
                this.app = response[0];
                this.$root.header = this.capitalize(this.app.appName) + ' app overview';
            }
        });

        this.getAppAccounts()
    },
    beforeMount() {
        this.$root.header = '';
        this.$root.activeMenu = 'connected-apps';
    }
})

export default class AppAccountOverview extends Vue{}
