

import {Options, Vue} from "vue-class-component";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";

@Options({
    components: {MappingTree, SchemaIterator},
    emits: ['schemaUpdate'],
    props: {
        requestSchema: Object,
        requestBody: Object,
        collectedData: Object,
        values: Object,
        path: String
    },
    data() {
        return {
            schemaValueObject: {},
            parameters: {},
            arrayItems: []
        }
    },
    beforeMount() {
        this.schemaValueObject = this.requestBody;
        this.generateValues(this.$props.requestSchema, this.schemaValueObject)
    },
    methods: {
        getValue(index: string): string
        {
            const realPath = (this.$props.path + '.' + index).replace('schema.', '');
            const parts = realPath.split('.');

            if (typeof this.$props.values === 'undefined') {
                return '';
            }

            let temp: any = JSON.parse(
                JSON.stringify(this.$props.values)
            );

            for (const i in parts) {
                if (temp.hasOwnProperty(parts[i])) {
                    temp = temp[parts[i]];
                } else {
                    temp = '';
                }
            }

            return temp;
        },
        getPath(index: string): string
        {
            return this.$props.path === '' ? index : this.$props.path + '.' + index;
        },
        generateValues(schema:any, object: any) {

            for (const i in schema) {
                if (schema[i].type === 'object') {
                    object[i] = {};
                }

                if (schema[i].type === 'array') {
                    object[i] = this.getValue(i);
                    this.addArrayItem(schema[i].items, []);
                }

                if (schema[i].type !== 'object' && schema[i].type !== 'array') {
                    object[i] = this.getValue(i);
                }
            }

        },
        addArrayItem(schema: any, ref: any) : void {
            this.arrayItems.push({[ref.length]:schema})
        },
        delArrayItem(reference: any, arrayIndex: number): void
        {
            const items = [];

            for (let i = 0; i < this.arrayItems.length; i++) {
                if (i !== arrayIndex) {
                    items.push(this.arrayItems[i]);
                }
            }

            this.arrayItems = items;
            reference.splice(arrayIndex, 1);

        }
    }
})


export default class SchemaIterator extends Vue{}

