import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SchemaIterator = _resolveComponent("SchemaIterator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_SchemaIterator, {
      requestSchema: this.$props.requestSchema,
      requestBody: this.schemaValueObject,
      collectedData: this.$props.collectedData,
      values: this.$props.requestBody,
      path: ''
    }, null, 8, ["requestSchema", "requestBody", "collectedData", "values"]),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.test && _ctx.test(...args)))
    }, "test")
  ]))
}