

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import Select from "@/components/Elements/Select.vue";
import CreateAccount from "@/components/Flowbuilder/Channel/AppAccount/CreateAccount.vue";
import App from "@/components/Flowbuilder/Apps/App";
import AppAccount from "@/models/AppAccount";
import AppAccountService from "@/service/AppAccountService";
import ChannelComponent from "@/components/Flowbuilder/Builder/Components/ChannelComponent";

@Options({
    components: {CreateAccount, Select},
    emits: ['appAccountSelected', 'appSelected'],
    props: {
        component: null,
        apps: Array
    },
    data() {
        return {
            selectedApp: null,
            appAccounts: [],
            selectAccountOptions: [],
            selectedOption: '',
            appAccountId: '',
            createNewAccount: false,
            appAccount: AppAccount,
            ready: false
        }
    },
    methods: {
        cancelCreateAccount() {
            this.setCreateNewAccount(false)
        },
        appAccountCreated(appAccount: AppAccount) {

            this.appAccount = appAccount;

            this.selectAccountOptions.push({
                id: appAccount.getId(),
                title: appAccount.getName()
            })

            this.selectedOption = appAccount.getId();

            this.appAccounts.push(appAccount);
            this.setExistingAccount(appAccount.getId());
            this.setCreateNewAccount(false)
        },
        setApp(app: App) {

            this.selectedApp = app;
            this.$emit('appSelected', app);
            const appAccountService = new AppAccountService();

            ApiRequest('GET', '/app/account?appName=' + app.getSlug()).then((response) => {
                this.selectAccountOptions = [];

                response.forEach((account: any) => {

                    this.selectAccountOptions.push({
                        id: account.id,
                        title: account.name
                    })

                    this.appAccounts.push(
                        appAccountService.createFromResponse(account)
                    )
                })

                this.ready = true;
            });
        },
        setExistingAccount(appAccountId: string) {
            this.appAccounts.forEach((appAccount: AppAccount) => {
                if (appAccount.getId() === appAccountId) {
                    this.appAccount = appAccount;
                }
            });
        },
        setAppAccount() {
            this.$emit('appAccountSelected', this.appAccount)
        },
        setCreateNewAccount(create: boolean) {
            this.createNewAccount = create;
        }
    },
    mounted() {

        if (typeof this.$props.component !== 'undefined') {

            const channel : ChannelComponent = this.$props.component as ChannelComponent;
            const app: App = channel.getApp();

            this.setApp(app);
            this.selectedOption = channel.getAppAccount().getId()
        }
    }
})

export default class ChannelAppAccount extends  Vue{}
