import ViewComponent from "@/components/Flowbuilder/Builder/ViewComponent";
import ChannelComponent from "@/components/Flowbuilder/Builder/Components/ChannelComponent";
import TriggerComponent from "@/components/Flowbuilder/Builder/Components/TriggerComponent";
import {ComponentType} from "@/components/Flowbuilder/Builder/Enum/ComponentType";
import App from "@/components/Flowbuilder/Apps/App";
import LoopComponent from "@/components/Flowbuilder/Builder/Components/LoopComponent";
import DecisionComponent from "@/components/Flowbuilder/Builder/Components/DecisionComponent";
import DecisionTrueComponent from "@/components/Flowbuilder/Builder/Components/DecisionTrueComponent";
import DecisionFalseComponent from "@/components/Flowbuilder/Builder/Components/DecisionFalseComponent";
import AppCollection from "@/components/Flowbuilder/Apps/AppCollection";
import AppAccount from "@/models/AppAccount";
import AppAccountConfig from "@/models/AppAccountConfig";
import FunnelComponent from "@/components/Flowbuilder/Builder/Components/FunnelComponent";


export default class FlowTransformer
{
    apps: AppCollection;

    constructor(apps: AppCollection) {
        this.apps = apps;
    }

    trigger(workflow: any): ViewComponent
    {

        const trigger: TriggerComponent = new TriggerComponent(
            workflow.triggerType,
            workflow.triggerParameters ?? {}
        );

        return new ViewComponent(
            ComponentType.TRIGGER,
            null,
            trigger
        );
    }

    getApp(operationId: string): App {

        const app: App | null = this.apps.getAppByOperationId(
            operationId
        );

        if (app !== null) {
            return app;
        }

        return new App('','','','',[],[],{});
    }

    getAppAccount(appAccount: any): AppAccount
    {
        const configs: AppAccountConfig[] = [];

        appAccount.configuration.forEach((config: any) => {
            configs.push(
                new AppAccountConfig(config.id, config.key, config.value, config.created)
            )
        });

        return new AppAccount(
            appAccount.id, appAccount.appName, appAccount.name, appAccount.userId, appAccount.created, configs
        );
    }

    transform(flow: any): ViewComponent[] {

        const collection: Array<ViewComponent> = [];

        if (typeof flow === 'undefined' || flow === null) {
            return [];
        }

        for (let i = 0; i < flow.length; i++) {

            if (flow[i].componentType === ComponentType.CHANNEL) {

                // flow[i] is a representation of data exported by  WorkflowExport::export()
                // the export will build an object and send it to the backend
                // Backend will store it raw into the database. When retrieving the workflow
                // the raw object is retrieved which was created by the export in the first place
                const component : ChannelComponent = new ChannelComponent(
                    null,
                    this.getApp(flow[i].action),
                    flow[i].action,
                    this.getAppAccount(flow[i].appAccount),
                    flow[i].mapping,
                    flow[i].parameters,
                    flow[i].componentReferenceId
                );

                const view : ViewComponent = new ViewComponent(
                    ComponentType.CHANNEL,
                    null,
                    component,
                    flow[i].x,
                    flow[i].y,
                );

                collection.push(view);
            }

            if (flow[i].componentType === ComponentType.FUNNEL) {
                const filter: FunnelComponent = new FunnelComponent(null, flow[i].field, flow[i].value, flow[i].comparison);
                const view : ViewComponent = new ViewComponent(ComponentType.FUNNEL, null, filter, flow[i].x, flow[i].y, this.transform(flow[i].childNodes))
                collection.push(view)
            }

            if (flow[i].componentType === ComponentType.DECISION_TRUE) {
                const componentTrue: DecisionTrueComponent = new DecisionTrueComponent();
                const view : ViewComponent = new ViewComponent(ComponentType.DECISION_TRUE, null, componentTrue, flow[i].x, flow[i].y, this.transform(flow[i].childNodes))
                collection.push(view);
            }

            if (flow[i].componentType === ComponentType.DECISION_FALSE) {
                const componentFalse: DecisionFalseComponent = new DecisionFalseComponent();
                const view : ViewComponent = new ViewComponent(ComponentType.DECISION_FALSE, null, componentFalse, flow[i].x, flow[i].y, this.transform(flow[i].childNodes))
                collection.push(view)
            }

            if (flow[i].componentType === ComponentType.DECISION) {
                const decision: DecisionComponent = new DecisionComponent(null, flow[i].rules, [], []);
                const view : ViewComponent = new ViewComponent(ComponentType.DECISION, null, decision, flow[i].x, flow[i].y, this.transform(flow[i].childNodes))
                collection.push(view)
            }

            if (flow[i].componentType === ComponentType.LOOP) {
                const component : LoopComponent = new LoopComponent(
                    flow[i].id,
                    flow[i].field,
                    []
                );

                const view : ViewComponent = new ViewComponent(
                    ComponentType.LOOP,
                    null,
                    component,
                    flow[i].x,
                    flow[i].y,
                    this.transform(flow[i].childNodes)
                );

                //view.setId(id)
                collection.push(view)
            }
        }

        return collection;
    }

    clone(path: string[]) : string[]
    {
        return JSON.parse(
            JSON.stringify(path)
        );
    }
}
