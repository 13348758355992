

import {Options, Vue} from "vue-class-component";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";
import ParameterWrapper from "@/components/Flowbuilder/Views/Apps/ParameterWrapper.vue";
import Description from "@/components/Flowbuilder/Helper/Description";
import DescriptionHelper from "@/components/Flowbuilder/Helper/DescriptionHelper";

@Options({
    components: {ParameterWrapper, MappingTree},
    emits: ['saveParameters'],
    props: {
        parameters: Array,
        collectedData: Array,
        appAccountId: String
    },
    data() {
        return {
            parameters: [],
            mappedValue: ''
        }
    },
    beforeMount() {
        this.parameters = this.$props.parameters;
    },
    methods: {
        getParameters()
        {
            return this.parameters;
        },
        getDescription(param: any): Description
        {
            return (new DescriptionHelper()).parse(param.description, param.name);
        }
    }
})


export default class OptionParameters extends Vue{}
