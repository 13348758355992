

import {Options, Vue} from "vue-class-component";
import DropDownSearch from "@/components/Elements/DropDownSearch.vue";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";
import SchemaIterator from "@/components/Flowbuilder/Channel/RequestBody/SchemaIterator.vue";

@Options({
    components: {SchemaIterator, MappingTree, DropDownSearch},
    props: {
        requestSchema: null,
        collectedData: Array,
        requestBody: Object
    },
    data() {
        return {
            selectMappings: [],
            mappingOptions: [],
            requestBody: Object,
            arrayTemplates: {},
            schemaValueObject: {}
        }
    },
    mounted() {
        this.requestBody = this.$props.requestBody;
        this.mappingOptions = [];
    },
    methods: {
        test() {
            console.log(this.$props.requestBody)
            console.log(this.schemaValueObject.schema)
        },
        getRequestBody() {
            return this.schemaValueObject.schema;
        },
        pathSelected(path: string, elementId: string): void
        {
            const id = elementId.replace('app_account', '');
            this.mapping[id] = path;
        },
        toggleSelectMap(index: number) {
            this.selectMappings[index] = !this.selectMappings[index];
        },
        setSearchOption(searchOption: any) {

            const map = document.getElementById( searchOption.id) as HTMLInputElement;
            map.value = searchOption.value;
            this.selectMappings[searchOption.id] = false;
            this.$props.requestSchema[searchOption.id] = searchOption.value;

        }
    }
})
export default class RequestBody extends Vue{}
