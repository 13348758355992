import Component from "@/components/Flowbuilder/Builder/Components/Component";
import App from "@/components/Flowbuilder/Apps/App";
import AppAccount from "@/models/AppAccount";
import AppParameter from "@/components/Flowbuilder/Apps/ActionParameter";

export default class ChannelComponent extends Component
{
    id: string | null;

    app : App;

    operationId = '';

    appAccount: AppAccount;

    requestBody: any = {};

    parameters: any[] = [];

    constructor(
        id: string | null,
        app: App,
        operationId: string,
        appAccount: AppAccount,
        requestBody: any,
        parameters: any,
        componentReferenceId: string
    ) {
        super(componentReferenceId);
        this.id = id;
        this.operationId = operationId;
        this.appAccount = appAccount;
        this.requestBody = requestBody;
        this.parameters = parameters;
        this.app = app;
    }

    getId(): string | null
    {
        return this.id;
    }

    getApp(): App
    {
        return this.app;
    }

    setApp(app: App): void
    {
        this.app = app;
    }

    getOperationId(): string
    {
        return this.operationId;
    }

    setOperationId(operationId: string)
    {
        this.operationId = operationId;
    }

    getAppAccount(): AppAccount
    {
        return this.appAccount;
    }

    setAppAccount(account: AppAccount): void
    {
        this.appAccount = account;
    }

    getParameters(): AppParameter[]
    {
        return this.parameters;
    }

    setParameters(parameters: any[]): void
    {
        this.parameters = parameters;
    }

    getRequestBody(): any
    {
        return this.requestBody;
    }

    setRequestBody(requestBody: any): void
    {
        this.requestBody = requestBody;
    }
}