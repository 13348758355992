

import {Options, Vue} from "vue-class-component";
import Select from "@/components/Elements/Select.vue";
import DropDownSearch from "@/components/Elements/DropDownSearch.vue";
import MappingTree from "@/components/Flowbuilder/Views/MappingTree/MappingTree.vue";

@Options({
    components: {MappingTree, DropDownSearch, Select},
    emits: ['saveRule'],
    props: {
        collectedData: Array,
        rules: Array
    },
    data() {
        return {
            tab: {
                active: 'default'
            },
            mappingOptions: [],
            comparisonOptions: [
                {id: 'eq', title: 'equals'},
                {id: 'ne', title: 'not equals'},
                {id: 'lt', title: 'lesser than'},
                {id: 'gt', title: 'greater than'},
                {id: 'ct', title: 'contains'},
            ],
            comparison: '',
            field: '',
            value: ''

        }
    },
    methods:{

        setComparison(comparison: string): void {
            this.comparison = comparison;
        },
        toggleSelectMap(index: number): void {
            this.selectMappings[index] = !this.selectMappings[index];
        },
        saveRule(): void {
            this.$emit('saveRule', this.field, this.comparison, this.value)
            this.value = '';
            this.field = '';
        },
        removeRule(index: number): void
        {
            this.$emit('removeRule', index)
        }
    }
})
export default class DecisionRuleDefault extends Vue{}
