

import {Options, Vue} from "vue-class-component";
import Node from "@/components/Flowbuilder/Views/MappingTree/Node.vue";
import MappingTreeBuilder from "@/components/Flowbuilder/Builder/MappingTree/MappingTreeBuilder";

@Options({
    components: {Node},
    props: {
        label: String,
        mapping: Array,
        id: String,
        visible: Boolean,
        displayButton: Boolean,
        leafIsSelectable: Boolean,
        collectionIsSelectable: Boolean,
        targetValue: String
    },
    emits: ['pathSelected', 'keyUp', 'update:targetValue'],
    data() {
        return {
            listeners: [],
            tree: Object,
            visible: Boolean,
            value: ''
        }
    },
    methods: {
        search(event: MouseEvent) {
            const val : string = (event.target as HTMLInputElement).value;
            this.tree.search(val)
            this.$emit('keyUp', val, this.$props.id)
        },
        toggleVisibility() {
            this.visible = !this.visible;
        }
    },
    mounted() {

        this.value = this.$props.value;

        this.visible = this.$props.visible;
        this.listeners.onPathSelected = (e: any) => {

            if ( e.detail.path !== '') {
                e.detail.path = '{{' + e.detail.path + '}}';
            }

            this.$emit('update:targetValue', e.detail.path)
            this.visible = false;
        };

        (document.getElementById(this.$props.id) as HTMLElement)
            .addEventListener('onPathSelected', this.listeners.onPathSelected)

        this.tree = new MappingTreeBuilder(
            this.$props.mapping,
            this.$props.id,
            this.$props.leafIsSelectable,
            this.$props.collectionIsSelectable
        )
        this.tree.create()
    },
    beforeUnmount() {
        (document.getElementById(this.$props.id) as HTMLElement)
            .removeEventListener('onPathSelected', this.listeners.onPathSelected)
    }
})


export default class MappingTree extends Vue{}
