import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }
const _hoisted_6 = { class: "button-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_ScheduleOnce = _resolveComponent("ScheduleOnce")!
  const _component_ScheduleRegularInterval = _resolveComponent("ScheduleRegularInterval")!
  const _component_ScheduleEveryDay = _resolveComponent("ScheduleEveryDay")!
  const _component_ScheduleWeekDays = _resolveComponent("ScheduleWeekDays")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Select, {
          id: 'schedule',
          ref: "selectTimeInterval",
          title: _ctx.$t('trigger.schedule.workflow-interval'),
          options: _ctx.selectOptions,
          selected: this.selectedOption,
          onOptionSelected: _ctx.setTriggerType
        }, null, 8, ["title", "options", "selected", "onOptionSelected"])
      ]),
      (_ctx.trigger.schedule.type === 'once')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_ScheduleOnce, {
              component: this.$props.component,
              onUpdateSchedule: _ctx.update
            }, null, 8, ["component", "onUpdateSchedule"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.trigger.schedule.type === 'regular_interval')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_ScheduleRegularInterval, {
              component: this.$props.component,
              onUpdateSchedule: _ctx.update
            }, null, 8, ["component", "onUpdateSchedule"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.trigger.schedule.type === 'every_day')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_ScheduleEveryDay, {
              component: this.$props.component,
              onUpdateSchedule: _ctx.update
            }, null, 8, ["component", "onUpdateSchedule"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.trigger.schedule.type === 'days_of_week')
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_ScheduleWeekDays, {
              component: this.$props.component,
              onUpdateSchedule: _ctx.update
            }, null, 8, ["component", "onUpdateSchedule"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("button", {
        class: "btn btn-default btn-action",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.save()))
      }, "Create schedule")
    ])
  ]))
}