

import {Options, Vue} from "vue-class-component";
import Select from "@/components/Elements/Select.vue";
import ChannelComponent from "@/components/Flowbuilder/Builder/Components/ChannelComponent";

@Options({
    components: {Select},
    props: {
        // actions parsed form yaml file
        // not AppActions[]
        actions: Array,
        component: null
    },
    emits: ['operationIdSelected'],
    data() {
        return {
            selectedApp: null,
            appAccounts: [],
            selectActionOptions: [],
            selectedOption: '',
            operationId: '',
        }
    },
    methods: {
        setOperationId(operationId: string) {
            this.$emit('operationIdSelected', operationId)
        }
    },
    beforeMount() {
        if (typeof this.$props.component !== 'undefined') {
            const channel : ChannelComponent = this.$props.component as ChannelComponent;
            this.selectedOption = channel.getOperationId();
        }
    }
})

export default class ChannelAction extends Vue{}
