

import {Options, Vue} from "vue-class-component";
import ApiRequest from "@/api/ApiRequest";
import Select from "@/components/Elements/Select.vue";
import {reactive, watch} from "vue";

@Options({
    components: {Select},
    props: {
        value: String,
        appAccountId: String,
        parameters: Array,
        collectedData: Array,
    },
    data() {
        return {
            foo: reactive({foo:1}),
            list: [],
            selected: {
                title: '',
                id: ''
            }
        }
    },
    beforeMount() {
        this.selected = {
            title: this.$props.value,
            id: this.$props.value,
        }
    },
    mounted() {

        watch(this.$props, () => {
            this.loadSheets();
        })

        this.loadSheets();
    },
    methods: {
        setFileId(fileId: string): void
        {
            this.$emit('paramValue', fileId)
        },
        loadSheets() {

            this.selected.title = '';
            this.selected.id = '';

            let driveId: string | null = null;
            let spreadsheetId: string | null = null;

            for(const i in this.$props.parameters) {
                if (this.$props.parameters[i].getName() === 'driveId') {
                    driveId = this.$props.parameters[i].getValue();
                }
            }

            if (driveId === null) {
                return;
            }

            for (const i in this.$props.parameters) {
                if (this.$props.parameters[i].getName() === 'spreadsheetId') {
                    spreadsheetId = this.$props.parameters[i].getValue();
                }
            }

            let queryString = '?appAccountId=' + this.appAccountId + '&driveId=' + driveId;

            ApiRequest('GET', '/google/spreadsheet/list' + queryString).then((response: any) => {
                this.list = [];
                for (const i in response) {
                    this.list.push({
                        id: response[i].id ?? '',
                        title: response[i].name,
                        description: response[i].name
                    })

                    let responseId = response[i].id === null
                        ? ''
                        : response[i].id;

                    if (responseId === spreadsheetId) {
                        this.selected.id = responseId;
                        this.selected.title = response[i].name;
                    }
                }
            })
        }
    }
})


export default class GoogleSheetsSpreadsheetId extends Vue {}

