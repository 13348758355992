import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mapping-container" }
const _hoisted_2 = { class: "search-container" }
const _hoisted_3 = ["value", "name"]
const _hoisted_4 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        value: _ctx.targetValue,
        "data-mapping": "1",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:targetValue', $event.target.value))),
        name: this.id,
        type: "text",
        class: "form-control search-box",
        placeholder: "type or pick"
      }, null, 40, _hoisted_3),
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleVisibility && _ctx.toggleVisibility(...args))),
        class: _normalizeClass('map-button ' + (!this.$props.displayButton ? 'hide' : '') + ' '  + (this.visible ? 'active' : ''))
      }, "map", 2)
    ]),
    _createElementVNode("div", {
      class: _normalizeClass('mapping-tree ' + (this.visible ? 'active' : 'hide')),
      id: this.$props.id
    }, null, 10, _hoisted_4)
  ]))
}